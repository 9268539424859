.home-content{
  position:relative;
  padding:20px 240px;
  margin:0 auto;

  @include respond-to('medium'){
    padding:0px 70px;
    top:0px;
    line-height: 1;
    letter-spacing: 0.3rem;
    }
    @include respond-to('extra-small'){
      padding:2px 5px;
    }

  &__title{
    line-height:1;
    @include respond-to('medium'){
      line-height: 1.5;
      }
    &_main{
     font-style: italic;
    font-size:25px;
    font-weight:bolder;
    text-shadow:2px 1px 3px ;
    color: $title-color;
    font-size:25px;
    @include respond-to('extra-small'){
          font-size:21px;
    }
    }
    &_sub{
      font-size:14px;
      font-weight:600;
      color: $color-black;
    }
  }

  &__article{
    font-size:18px;
    color: $color-black;
    padding:10px;
&_par{
  line-height:2;
  font-weight: 600;
  position:relative;
   z-index:11111111111111111111111111111111111111111111119;
  @include respond-to('medium'){
  letter-spacing: 0rem;
    line-height: 1.5;

    }

}
  }
}
