.content-page{
  display: table;
  &__row{
    display: table-row;
  } //__row
  &__side{
    display: table-cell;
    vertical-align: top;
    padding: 40px;
    position: relative;

    @include respond-to('small'){
      display: block;
      padding: 15px;
    }

    &_hide-s{
      @include respond-to('small'){
        display: none;
      }
    }
    &_show-s{
      display: none;
      @include respond-to('small'){
        display: block;
      }
    }

    &_article{
      width: 77%;
      box-shadow: 120px 0 120px 0px rgba(0,0,0,.1);

      @include respond-to('small'){
        width: 100%;
      }

      &:after{
        position: absolute;
        top: 0;
        #{$right}: 0;
        content: '';
        width: 2px;
        height: 100%;
        box-shadow: 1px 0 2px rgba(0,0,0,.08);
      }
    } //_article
    &_menu{
      width: 23%;

      @include respond-to('small'){
        width: 100%;
      }
    } //_menu
  } //__side
  &__menu{
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 15px;
    z-index: 1;
    position: relative;
  } //__menu
  &__menu-list{
    list-style-type: none;
    padding: 0;
  } //__menu-list
  &__menu-item{
    position: relative;
    width: 100%;
    margin-bottom: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    transition: .2s;
    top: 0;
    left: 0;
    min-height: 35px;

    @include respond-to('small'){
      width: calc(33% - 10px);
      margin: 5px;
      float: $left;
    }

    &:hover{
      box-shadow: 1px 2px 12px rgba(0,0,0,.1);
      top: -2px;
      left: -1px;
    } //HOVER
    &:active{
      box-shadow: 0px 0px 2px rgba(0,0,0,.4);
      top: 1px;
      left: 1px;
    }

    &_pic{
      background-position: center;
      background-size: cover;
      height: 125px;
    } //_pic

    a{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    } //a
    span{
      padding: 8px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      line-height: 15px;
      color: $color-turquise;
      font-size: 16px;
      text-align: center;
      background-color: rgba(255,255,255,.75);
    } //span
  } //__menu-item
} //content-page

.content-main{
  font-family:'Assistant',sans-serif;
  position: relative;
  z-index:100;

}
.content-banner{
position: relative;
 @include respond-to('medium'){
     background-size: contain;
     margin-top: 70px;
 }


 &__height{
       //width:$full-width;
     height:auto;
     margin: 0px;
     visibility: hidden;

     @include respond-to('small'){
      height: 150px;
     }
 }
  &__image {
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    //max-height: 60vh;
    height: 330px;
    @include respond-to('large'){
          height:auto;
         margin: 0px;
    }
    @include respond-to('small'){

    }
  }
}

 .content-article {
   background-color: #fff;
   position:relative;
   padding: 0;
   text-align: justify;

   @include respond-to('large'){
   width:100%;
    margin: 0px;
    padding:0;
   }
   &__article{
     margin-top: 20px;
   }
&__img{
  box-shadow: -6px 4px 29px 0px rgba(0,0,0,0.15),3px -8px 29px 0px rgba(0,0,0,0.15);
  margin: 20px 0px;
  width: 100%;

  &_form{
    float: right;
    margin: 0;
    max-width: 100%;
    max-height: 500px;
    width: auto;
    height: auto;
  }
}

    &__title {
		  font-size:28px;
      font-weight: 700;
    	color: $color-turquise;
      margin: 0 0 45px;
   }

	 &_sub{
   font-size:16px;
   font-weight:400;
 	  color: $color-turquise;
   }

	&__par {
		font-size: 16px;
		font-weight: 500;
    color: $color-black;
  	}
  }

  .content-contact{

    &__box{
      text-align: $right;
      background:$contact-bg-color;
      border:1px solid $contact-bg-color;
      border-radius: 15px;
      padding:20px;
    }
    &__title{
      font-size:25px;
      font-weight:bold;

    }
    &__sub{
      font-size:20px;
      font-weight:400;
    }
  &__form{
    padding:10px;
    textarea , input {
      background: $input-bg;
      &::placeholder{
        color:$main-color;
        border-color:#4e4e4e;
  }
}
}

&__details{
  &_list{
    list-style-type: none;
    &_item{
      margin-bottom:50px;
    }
  }
  a{
    color:#777777;
      font-size:18px;
  }
  img{
    width:40px;
    height:auto;
    float:$right;
    margin-left:30px;
  }
  &_title{
    font-size:25px;
    font-weight:bold;
    padding:30px;
    color: $alt-title-color;
  }
}
}

.articles-btn{
  width: 100%;
  height: 175px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
  box-shadow: none;
  padding: 15px;
  transition: .2s;

  &:hover{
    box-shadow: 1px 1px 30px rgba(0,0,0,.03);
  }

  &__title{
    color: $brand-color;
    margin: 0 0 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #999;
    font-size: 22px;
  }
  &__text{
    color: #000;
    font-size: 18px;
  }
} //articles-btn

.content-form{
  //border: 1px solid $color-turquise;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  background-color: #fff;
  padding: 20px;
  position: relative;
  z-index: 1;

  @include ph-color(#888);
  @include ph-size(14px);

  &__title{
    position: relative;
    z-index: 1;
    font-size: 20px;
    color: $color-turquise;
    margin-top: 25px;
    margin-bottom: 30px;
    font-weight: 400;
  } //__title

  &__para{
    font-size: 14px;
    color: #000;
    font-weight: 400;
  } //__para

  &__input{
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    margin-top: 10px;
    width: 100%;
    padding: 0 6px;
    height: 35px;
    z-index: 1;
  } //__input
  &__input-text{

    &:first-of-type{
      margin-top: 30px;
    }
  } //__input-text
  &__textarea{
    border: 1px solid #e2e2e2;
    resize: none;
    height: 65px;
  } //__textarea
  &__submit{
    background-color: $color-turquise;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    padding: 5px;
    border: none;
  } //__submit
} //content-form

.content-slider{
  position: relative;
  &__title{
    font-size: 18px;
    color: $color-turquise;
    font-weight: 400;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 70px;
    display: block;
    text-align: $right;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      box-sizing: border-box;
      border-top: 1px solid #d7d7d7;
      border-bottom: 1px solid #fff;
    }
  } //__title
  &__slider{
    max-width: 200px;

    @include respond-to('small'){
      max-width: 320px;
      margin: 0 auto;
    }
  } //__slider
  &__slide{
    p{
      font-size: 16px;
      font-weight: 400;
      color: #000;
      text-align: justify;
      margin-bottom: 20px;
    } //p
    span{
      font-size: 14px;
      font-weight: 400;
      color: $color-turquise;
      text-align: $right;
    } //span
  } //__slide
  &__arrows{
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1;

  } //__arrows
  &__btn-wrapper{
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    width: 50px;
    margin: 0 auto;

  }
  &__btn{
    width: 25px;
    height: 25px;
    background-color: #fff;
    color: $color-turquise;
    transition: .2s;
    text-align: center;
    float: $right;
    border: none;
    font-weight: 700;
    font-family: 'Arial', sans-serif;
    font-size: 14px;

    &:hover{
      background-color: $color-turquise;
      color: #fff;
    } //HOVER
  } //__btn
} //content-slider

.content-link{
  width: 100%;
  text-align: center;
  margin-top: 70px;
} //content-link
