.home-wrapper{
  position: relative;
  height: 900px;
  max-height: 75vh;
  margin: 0;

  /*.box{
    height: 100%;
  } */
} //home-wrapper
.main-banner{
  width: 100%;
  margin-top: 0px;
  height: 100%;
  overflow: hidden;

  img{
    opacity: 0;
  }

  @include respond-to('small'){
    margin-top: 50px;

    img{

    }
  } //---small
  &__single{
    position: relative;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
} //main-banner
.home-banner{
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  position:relative;
  z-index:1;

  @include respond-to('medium'){
  background-color: #fff;
  background-position: center;
  }

/*  &__wrapper{
    overflow: hidden;
    @include respond-to('extra-small'){

              height: 50vh;

    }
  }*/

  /*&__video{
    position: relative;
    min-width: 100%;
    width: $max-width;
    z-index: -100;
    background: url(../videos/Amirim.png) no-repeat;
    background-attachment: fixed;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @include respond-to('medium'){
      width:auto;
    }
    @include respond-to('smallest'){
      width:auto;
      height:auto;
    }
  }*/



     &__overlay{
       position: absolute;
       width: 100%;
       bottom: 0;
       left: 0;

       @include respond-to('huge'){
        //top:  -52vh;
       }
       @include respond-to('large'){
      //  top:  -48vh;
       }
       @include respond-to('medium'){
    //         top:  -24vh;
       }
       @include respond-to('small'){
  //      top:  -95vh;
       }
       @include respond-to('smaller'){
//            top:  -20vh;

       }
       @include respond-to('extra-small'){
        //position: relative;

       }
       @include respond-to('smallest'){
        //position: relative;
            //top:  -22vh;


       }
     }
     &__text {
/* overlay text  (green & blue ) */
       font-family: $banner-font-stack;
       position: relative;
       float: $left;
       margin:0 auto;
        //#{$left}:0;
        z-index: 7;
        color:$color-white;
        font-weight: bold;
         text-shadow: 1px 2px 3px rgba(01, 01, 01, 0.25);
         width:333px;
         margin-#{$left}:40px;
        z-index:19;

        @include respond-to('small'){
          padding:0;
           width:40%;
    //  #{$left}:-6vh;
        }
        @include respond-to('smaller'){


        }
        @include respond-to('extra-small'){
         position: relative;
         padding:0;
         width:38%;
         margin-#{$left}:10px;
        }

        &_link{
          //position: relative;
        } //_link
       &_title{
         background:  $banner-title-bg-color;
         font-size:32px;
         margin-bottom:7px;
         border-radius: 6px;
         width:333px;
         padding:20px 15px;
         font-weight: 700;
         margin-top: 0;
         @include respond-to('medium'){
            font-size:18px;
          width:auto;
          padding:15px 7px;
         }
         @include respond-to('small'){
           font-size:16px;
       padding:20px 15px;
         }
         @include respond-to('extra-small'){
           font-size:12px;
              padding:3px;
         }
         @include respond-to('smallest'){
           font-size:11px;
                   padding:0;
         }
       }
       &_sub{
            background:  $banner-sub-bg-color;
            font-size:21px;
            margin-bottom:7px;
            border-radius: 6px;
            padding:13px 30px;
            text-align: justify;
            position:relative;
            @include respond-to('medium'){
               font-size:16px;
               width:auto;
              margin-bottom:7px;
            }

          @include respond-to('small'){
            font-size:14px;
            padding:7px 4px;
           text-align: center;
          }
          @include respond-to('extra-small'){
            /*font-size:11px;
            padding:2px;*/
          }
          @include respond-to('smallest'){
            font-size:9px;
              padding:01px;
          }
       }
      &_arrow{
        /* position :relative;
        bottom:0px;
        #{$left}:80px;
         transition: all 0.5s ease;
        font-family:'Trebuchet MS' serif;
         margin: 0;
        font-size: 14px;
        font-weight: 400;
        background:$banner-arrow-bg-color;
        border-radius: 10px 2px 2px 10px;
        width:23px;
        text-align: center;
        line-height:13px;
        transform:scale(1.5,3);*/

        position: absolute;
        bottom: 0;
        #{$right}:-50px;
        margin: 0;
        font-size: 24px;
        font-weight: 400;
        background:$banner-arrow-bg-color;
        border-radius: 5px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 38px;

        &:hover {

        }
        @include respond-to('medium'){
      #{$left}:60px;
        }
        @include respond-to('small'){
      #{$left}:60px;
        }
        @include respond-to('extra-small'){
        /*#{$right}:0px;
        bottom: -30px;
        width: 25px;
        height: 25px;
        font-size: 20px;
        line-height: 25px;*/

        }
        @include respond-to('smallest'){
        }
      }

     }

     &__navbar{
       position: relative;
       width: 100%;

       @include respond-to('medium'){

       }
       @include respond-to('small'){
          //top: 25vh;
       }
       @include respond-to('smaller'){
          //top:0;
       }
       @include respond-to('extra-small'){

       }
       @include respond-to('smallest'){

       }
       &_menu{
        position: relative;
        text-align: center;
        list-style-type: none;

        @include respond-to('medium'){
          z-index:8;
          margin: 5px;
          padding:0;
        }
       }
       &_item{
         z-index: 3;
          transition: all 0.5s ease;
         display:block;
         width: 135px;
         height:135px;
         display: inline-block;
         position: relative;
         background-color: transparent;
         transition: all 0.3s ease;
         border-radius: 50%;
         margin: 0 35px 45px;
         box-shadow: none;

         &:first-of-type{
           margin-#{$right}: 0;
         }
         &:last-of-type{
           margin-#{$left}: 0;
         }

         $ni: &;
         @include respond-to('large'){
           //top:10vh;
           //display:inline-block;
           //padding:0;
           //max-height:12vh;
           //width:auto;
           width: calc(16% - 10px);
           float: $right;
           margin: 0 5px 45px;
         }
         @include respond-to('small'){
          //max-height:18vh;
         }
         @include respond-to('smaller'){
        //  max-height:18vh;
         }
         @include respond-to('extra-small'){
            height: 100px;
            width: calc(33% - 10px);
         }
         @include respond-to('smallest'){
        //  max-height:10vh;
        //    max-width:10vh;
         }
            &:hover{
                background-color: #fff;
                box-shadow: 0 0 15px rgba(0,0,0,.25);

                #{$ni}_img{
                  filter: brightness(1) invert(0);
                }
               }

            a{
              display: block;
              width: 100%;
              height: 100%;
            }

           &_img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            //margin-top: 23px;
            max-width: 70%;
            transition: .3s;
            filter: brightness(0) invert(1);

            @include respond-to('small'){
                padding:  0;
            }
            }

          &_text{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%,100%);
            margin: 0;
            white-space: nowrap;
            color: #fff;
            font-size: 22px;
            font-weight: 700;

            @include respond-to('medium'){
              font-size: 12px;
              padding:0;
                white-space:normal;
            }
            @include respond-to('extra-small'){
               //transform: translate(-50%,25%);
            }
             @include respond-to('smallest'){
                      font-size: 9px;
                      padding:0;
                        white-space:nowrap;
                    }

          }
         }
       }
     }
