#home_grid5{
	@include respond-to('medium'){
		#{$left}: 50%;
	}
	@include respond-to('small'){
		#{$left}: unset;
	}
} //home_grid5

.home-grid
{
	font-family:$alt-font-stack;
	display:block;
	padding-top:0;
	padding-bottom:$grid-padding-bottom;
	position:relative;
					top:0px;
	margin: 0 auto;
  text-align:$right;
		z-index: 4;
	  background: $color-white;
		box-shadow: 1px 2px 25px #dfdfdf ;
		border-bottom:inset  4px solid darken(#dfdfdf, 0.2);
		@include respond-to('medium')	{
					top: 0px;
			}
			@include respond-to('small')	{

				}

	&__link{
		display: block;
	} //__link

&__wrapper{

}
	&__img
{

		background-repeat: no-repeat;
		background-size: cover;
		//padding: calc(100%/3) ;
		height: 260px;
		position: relative;
		margin-top:$grid-padding-top;

		&:hover{
				opacity:0.5;
				z-index:1;
				position:relative;

 		}
/* == = large  */
		&_lg	{
		height: 550px;
		@include respond-to('medium'){
			 height:240px;
			}
	}

	@include respond-to('small')
{
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		}
}

	/*  =end  large  */
&__text {
	z-index: 2;
  position: absolute;
		bottom: 0;
	transition:all 0.5s ease;
	width:100%;



    &_lg  {

  		}

	&_title
{
		color: #0068b1;
	  background:transparentize($color-white,0.25);
		line-height:33px;
		font-size: 25px;
		font-weight: bold;
		padding:$gtpy $gtpx;
		@include respond-to('small')	{
			font-size: 2.1rem;
			line-height:3.5rem;

			}
}
	&_sub
{
		background:transparentize($color-white,0.25);
		color: #009ab1;
 		font-size: 16px;
		font-weight: 400;
		padding:$gtpy $gtpx;
		}
// 	&_price
// {
// 		background: transparentize($bg-color,0.25);
// 		color: $color-white;
// 		position: relative;
//     float:$left;
//     padding:10px 13px ;
//
// 		&_start
// {
// 			font-size: 1.3rem;
// 			}
// 		}
// 	&_num
// {
// 		font-size: 1.9rem;
// 		font-weight: 700;
// 		}
 	}
}
