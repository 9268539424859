@charset 'UTF-8';

$dir: rtl !default;
@import 'styles_base';

.pull-right{
  float:right !important;
}

.pull-left{
  float:left !important;
}
