
.main-footer{
  width: 100%;
  position: relative;
  bottom: 0px;
  overflow: hidden;
  background-color: $footer-bg-color;
  padding-top: $footer-padding ;
  z-index: 2;

  @include respond-to('small'){
    padding-bottom: 30px;
    padding-top: 20px;
  }


  @at-root #{&}_padding{
    @at-root #{&}_15{
      padding: 0 15px;
    } //_15
  } //_padding


  @at-root #{&}__soc{
    direction: $dir;
    position: relative;
    top: 20px;

    a{
      padding-#{$right}: 25px;
      @include respond-to('large'){
      padding-#{$right}: 10px;
      }
      i{
        color: #fff;
        transition: .2s;
      } //i

      &:hover{
        i{
          color: $brand-color;
        } //i
      } //hover
    } //a
    @include respond-to('large'){
      top: unset;
      bottom: 5px;
    }
    @include respond-to('medium'){
          position: unset;
       float:$right;
     }
    @include respond-to('small'){
      float: none !important;
      bottom: 0;
    } //---small
    @include respond-to('extra-small'){
      position: absolute;
      bottom: 70px;
      #{$left}: 0;
    }
    @include respond-to('smallest'){
      top:0;
      #{$right}: -10px;
    }
  } //__soc
} //main-footer

.footer-contact{
  @at-root #{&}__form {
    @include ph-color(#fff);
    @include ph-size(18px, 200);
    width: 310px;
    position: relative;
    //font-family:$footer-font-stack;
    @include respond-to('large'){
    width: unset;
    } //---large
    @include respond-to('medium'){
          width: unset;

        padding-bottom:30px;
    } //---small

  } //__form
  @at-root #{&}__title {
    color: $color-green;
    font-size: 26px;
    line-height: 26px;
    font-weight: 600;
    display: block;

    @include respond-to('medium'){
      //font-size: 36px;
      line-height: 36px;
    }
  } //__title
  @at-root #{&}__sub {
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    margin-bottom: 25px;
    display: block;

    @include respond-to('medium'){
      //font-size: 24px;
    }
  } //__sub
  @at-root #{&}__input {
    width: 100%;
    background-color: #252525;
    height: 40px;
  } //__input
  @at-root #{&}__input-text {
    border: 1px solid #555;
    padding-#{$right}: 15px;
    margin-bottom: 15px;
    color: #fff;
    font-weight: 400;
  } //__input-text
  @at-root #{&}__submit {
    border: 1px solid $color-green;
    background-color: $footer-title-color;
    color: $color-black;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 20px;
  } //__input-submit
  @at-root #{&}__checkbox {
    opacity: 0;

    &:checked{
      &+label{
        &:after{
          opacity: 1;
          transform: rotate(-45deg) scale(1);
        } //after
      } //label
    } //checked

  } //__checkbox
  @at-root #{&}__checkbox-label {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;

    @include respond-to('medium'){
        float: $right;
        margin-#{$right}: 35px;
    }
    @include respond-to('smallest'){
        float: $right;
        margin-#{$right}: 35px;
    }
  } //__checkbox-label
  @at-root #{&}__checkbox-controller{
    &:before{
      content: '';
      width: 20px;
      height: 20px;
      border: 1px solid #555;
      display: block;
      position: absolute;
      background:$color-black;
      border-radius: 50%;
      #{$right}: 0px;
      top:1px;
    } //before
    &:after{
      content: '';
      position: absolute;
      #{$right}: 4px;
      top:6px;
      transform: rotate(-30deg) scale(.75);
      border-bottom: solid 2px #fff;
      border-left: solid 2px #fff;
      width: 12px;
      height: 7px;
      opacity: 0;
      transition: .25s;
      @include respond-to('medium'){

      }
      @include respond-to('smallest'){


      }
    } //after
  } //__checkbox-controller
} //footer-contact

.footer-links-counter{
  margin-#{$right}: 10px;
  @include respond-to('small'){
    margin: 0;
  }
  &:first-of-type{
    margin-#{$right}: -30px;
    @include respond-to('small'){
      margin: 0;
    }
    .footer-links{
      padding-#{$right}: 5px;
      @include respond-to('small'){
        padding: 0;
      }
    }
  }
}
.footer-links{
  //padding-#{$left}: 5px;
  width: 200px;
  float: #{$left};
//font-family:$footer-font-stack;
  @include respond-to('large'){
    width: auto;
    float: none;
  } //---large


  &:hover{
    .footer-links__border_highlight{
      transform: translateX(0);
      transition: .3s;
    }
  }
  @at-root #{&}__title {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
    display: block;
    color: #e3e3e3;
    padding-#{$right}:40px;
    white-space: nowrap;
    @include respond-to('smallest'){
    font-size: 14px;
        padding-#{$right}:0px;
    }
  } //__title
  @at-root #{&}__border {
    width: 100%;
    height: 1px;
    overflow: hidden;
    background-color: #555;
    margin-top: 20px;
    position: relative;
    @at-root #{&}_highlight {
      width: 200%;
      height: 100%;
      background-color: $brand-color;
  //    background: linear-gradient(to right, $brand-color 0, $brand-color 50%, transparent 100%);
      position: absolute;
      top: 0;
      #{$right}: 0;
      transform: translateX(-100%);
      transition: 0.5s;
    } //_highlight
  } //__border
  ul {
    list-style-type: none;
    @include respond-to('extra-small'){
    padding:0;
    }
    li {
      color: $color-green;
      font-weight: 300;
      font-size: 14px;
      //line-height: 30px;

      a{
        color: #e3e3e3;
        font-weight: 300;
        font-size: 14px;
        line-height: 30px;

        @include respond-to('small'){
          line-height: 20px;
        }
        @include respond-to('smaller'){
          //white-space: nowrap;
          font-size: 12px;

        }
        @include respond-to('extra-small'){
          padding:0;
          line-height: 10px;
          font-size: 10px;
        }
        @include respond-to('smallest'){
              font-size: 10px;
        }
        &:hover{
          color: $brand-color;
        } //hover
      } //a
    } //li
  } //ul
} //footer-links

.footer-separator{
  width: 100%;
  height: 1px;
  margin-top: 50px;
  background-color: #555;

  @include respond-to('medium'){
    margin-top: 20px;
    margin-bottom: 30px;
  } //---medium
  @include respond-to('extra-small'){
    margin-bottom: 5px;

  } //---medium
} //footer-separator

.footer-bottom{
  background:$footer-bg-color;
  width:100%;
  max-height:80px;

}
.footer-contacts{
  padding: 20px 0;
  @include respond-to('large'){
   padding:  1px;
  }
  @include respond-to('medium'){
  padding:  1px;

  }
  @include respond-to('small'){
    float: none !important;


      text-align: center;

   }
  @include respond-to('smallest'){

  }
  span, a{
    color: $footer-text-color;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    margin-#{$left}: 16px;
    @include respond-to('large'){
      line-height: 1.4;
       font-size: 14.5px;
    }
    @include respond-to('medium'){
      line-height: 1.5;

    }
    @include respond-to('small'){
      line-height: 1.5;
          margin: 3px 16px;
    }
    @include respond-to('smaller'){
        line-height: 1;
          margin-#{$left}:1px;
    }
    @include respond-to('smallest'){
    font-size: 11px;
    line-height:0.1;
    }

    &:after{
      content: '|';
      #{$left}: -10px;
      position: relative;
      color: #fff;
      @include respond-to('small'){
           visibility: hidden;
      }
      @include respond-to('smaller'){
        #{$left}:  -5px;
      }
      @include respond-to('extra-small'){

      }
      @include respond-to('smallest'){
       visibility: hidden;
      }
    } //after

    &:last-of-type{
      &:after{
        content: '';
      } //after
    } //last

  } //span
} //footer-contacts

.cs_logo_png{
  position: relative !important;
  #{$left}: 10px !important;
  #{$right}: unset !important;
  float:$left;
  bottom: unset !important;
  margin: 15px 0 !important;

  filter:invert(100%);
  & > a{
    display:inline-block;
    width:100%;
    height:100%;
  }
    &:hover {
        filter:hue-rotate(12deg);
        opacity: 0.6;

  }
  @include respond-to('large'){
    position: absolute !important;
    top: unset !important;
    bottom: 10px !important;
  #{$left}: 30px !important;
  } //---small
  @include respond-to('medium'){
    position: absolute !important;
    top: unset !important;
    bottom: 10px !important;

  } //---small
  @include respond-to('small'){
      position: relative;
      text-align:center;
  transform: translateX( 0%) !important;
     bottom: 20px !important;

  }
  @include respond-to('extra-small'){
        text-align:center;


    }
    @include respond-to('smallest'){
        position: absolute !important;
        transform: translateY( 120%) !important;
           bottom: 20px !important;


      }

   }
