// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Regular font family
/// @type List
$banner-font-stack:'Open Sans Hebrew', sans-serif  !default;;
$text-font-stack: 'Heebo', sans-serif;
$alt-font-stack: 'Assistant', sans-serif;
$footer-font-stack: 'Heebo Black', sans-serif;
$custom-font-stack: 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
/// @type Color
  $color-white: #ffffff !default;
  $color-black: #000000 !default;
  $text-color: #00a9c3 !default;
  $brand-color: #00a9c3 !default;
  $light-color: #a1f803 !default;
  $main-color:#0a6ea7;
  $title-color:#0a6ea7;
  $alt-title-color: #527b09;
  $alt-text-color:$color-white;

  $bg-color:#609206;
  $alt-bg-color:#658b27;
  $input-bg:#f8f8f8;

  $banner-title-bg-color:transparentize(#77ac17,0.25);
  $banner-sub-bg-color:transparentize(#009cff,0.25);
  $banner-arrow-bg-color:transparentize(#b06123,0.25);

  $green-color:#109D13;
  $blue-color:  #03AEDE;
  $brown-color:#B66B2E;
  $color-yellow: #fffbbb;
  $color-green: #72af1a;
  $color-turquise: #0092ac;
  //@type general
  $full-width:2000px !default;
  $limit-width: 1230px !default;


$padding-base: 15px;
$padding-base-box: 20px;

$max-width: $limit-width + (2 * $padding-base) !default;
$max-width-mini: 1180 + calc(2 * $padding-base) !default;
$max-width-maxi: 1340 + calc(2 * $padding-base) !default;
//header
$section-spacer-height:55px !default;

//@type breakpoints
$breakpoints: (
  'mobile-m': (max-width: 375px),
  'mobile-l': (max-width: 425px),
  'smallest': (max-width: 375px),
  'extra-small': (max-width: 480px),
  'smaller': (max-width: 624px),
  'small': (max-width: 768px),
  'medium': (max-width: 992px),
  'large': (max-width: 1200px),
  'larger': (max-width: 1300px),
  'huge': (max-width: 1400px),
) !default;
//@type font sizes
$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

// social colors
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

@if $dir != ltr and $dir != rtl {
    $dir: ltr;
}



$left: if-ltr(right, left);
$right: if-ltr(left, right);

//header
 $header-position:fixed;
 /*$nav-item-padding-top */     $nip-tp :10px;
/* $nav-item-padding-bottom*/   $nip-bt:  11px;
/* $nav-item-padding-right*/    $nip-rt:  14px;
/* $nav-item-padding-left*/      $nip-lt:  8px;
 $nav-item-max-height: 34px - ($nip-tp +  $nip-bt);
$nav-item-margin:  1%;  //43

$navbar-max-width: 1400px;
$nav-item-count:7;
//160px; // 26px
$light-border-color: transparentize(#EFEEEE,0.25);
    $border-color:transparentize(#9F9B9B,0.25);

 /*$grid-txt-pad-x*/ $gtpx:3.5px;
 /*$grid-txt-pad-y*/ $gtpy:1.75px;
$grid-padding-top:30px;
$grid-padding-bottom:70px;

$contact-bg-color: #fcfcfc;

//footer variables
$color-green:#77ac17;
$footer-title-color:#77ac17;
$footer-text-color:$light-color;
$footer-bg-color:#506d51;
$footer-padding:70px;
$footer-padding-bottom: 55px;
$footer-min-height: 800px;

$sideform-submit-bg:#528704;
