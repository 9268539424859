// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}


@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin box-shadow($top, $left, $bottom, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $bottom $blur $color;
    -moz-box-shadow:inset $top $left $bottom $blur $color;
    box-shadow:inset $top $left $bottom $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $bottom $blur $color;
    -moz-box-shadow: $top $left $bottom $blur $color;
    box-shadow: $top $left $bottom $blur $color;
  }
}

@mixin text-field {
 display: inline-block;
  outline: none;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: .5em;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  @include rounded();
  @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
}

@mixin button($color: $red, $text_color: $color-white) {
  display: inline-block;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: .5em 2em .55em;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  @include rounded();
  @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));

  color: $text_color !important;
  font-weight: bold;
  border: solid 1px darken($color, 18%);
  background: $color;
  @include gradient(saturate($color, 15%), darken($color, 15%));

  &:hover {
    text-decoration: none;
    background: saturate($color, 10%);
    @include gradient(saturate($color, 5%), darken($color, 5%));
  }

  &:active {
    position: relative;
    top: 1px;
    color: saturate($color, 15%);
    @include gradient(saturate($color, 15%), lighten($color, 15%));
  }
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top,  $from, $to);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}


@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  transition: $args;
}

@mixin transform($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  transform: $args;
}

@mixin border-radius($args) {
  -webkit-border-radius: $args;
  -moz-border-radius: $args;
  -ms-border-radius: $args;
  border-radius: $args;
}

@mixin btn-effect-active(){
  @include box-shadow(2px, 5px, 5px, rgba(0,0,0,.15), inset);
}

@mixin icon-styles(){
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin ellipsis(){
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 100%;
}

@mixin if-ltr {
  @if $dir != rtl {
    @content;
  }
}

@mixin if-rtl {
  @if $dir == rtl {
    @content;
  }
}

@mixin ph-color($ph-color){
  ::-webkit-input-placeholder {
    color: $ph-color;
  }
  ::-moz-placeholder {
    color: $ph-color;
  }
  :-ms-input-placeholder {
    color: $ph-color;
  }
  :-moz-placeholder {
    color: $ph-color;
  }
}

@mixin ph-size($ph-size, $font-weight: 400){
  ::-webkit-input-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
  ::-moz-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
  :-ms-input-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
  :-moz-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
}
