// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.section-full{
  width:100%;
}

.section-limit{
  width:100%;
  max-width:$max-width;
  margin: 0 auto;
  padding:0px $padding-base;

  &_relative{
    position: relative;
  }
}

.section-spacer{
  width:100%;
  height:$section-spacer-height;
}

img{
   max-width:100%;
}

.img-not-responsive{
  max-width:auto;
}

.no-padding{
  padding:0;
}
.no-margin{
  margin:0;
}
.no-margin-mobile{
  @include respond-to('medium') {
    margin:0px 15px;
  }
}

.no-row-padding{
    & > div[class^="col-"]{
      padding:0;
    }
}

.no-margin-bottom{
  margin-bottom:0 !important;
}

iframe{
  max-width:100% !important;
}

.text-color-white{
  color:$color-white;
}
.text-color-black{
  color:$color-black;
}
.text-main{
  color:$main-color;
}
.text-brand{
  color:$brand-color;
}
