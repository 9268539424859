/* body.scrolling .header */

/*body.scrolling .header {
	&-desktop {
		top: 0;
		width: 100%;
	}

	&-main {
		background: transparentize($color-white,0.1);
		max-height: 50px;
		padding: 7px;
		position: $header-position;
		top: 0;

		&__logo {
			cursor: pointer;
			transform: scale(0.6,0.6);
			top: -30px;
			left: -50px;
			opacity: 0;
			margin-bottom: 250px;
			transition: all 0.5s ease;

			&_sm {
				opacity: 1;
				position: absolute;
				transition: all 0.5s ease;
				z-index: 3;
				display: inline-block;
				top: 0;
				float: $left;
				left: 50px;
				position: relative;
				width: 150px;

			}
		}
	}
}*/

.header-desktop {
	background: linear-gradient(to bottom, rgba(0,0,0,0.6) 5%, rgba(0,0,0,0.48) 30%, rgba(0,0,0,0.06) 90%, rgba(0,0,0,0) 100%);
	height: 250px;
	z-index: 4;
	position: $header-position;
	top: 0;
	width: 100%;
	box-shadow: none;
	pointer-events: none;

	@include respond-to('medium') {
		display: none;
	}
}

.header-main {
	z-index: 1;
	//padding: 0 0 50px;
	position: relative;
	margin: 0 auto;
	width: 100%;

	&__menu {
		display: inline-block;
		position: relative;
		padding: 0;
		margin: 50px 0 0;
		@include respond-to('huge') {
			margin: 0;
			padding: 0;
		}

		&_link {
			color: $color-yellow;

			&:hover,
			.selected & {
				filter: brightness(.8);
			}
			@include respond-to('huge') {
				padding: 8px 1px;
			}
		}
		/*menu link*/
		&_item {
			display: inline-block;
			list-style-type: none;
			color: $color-black;
			margin-left: 8px;
			font-size: 18px;
			font-weight: 500;
			z-index: 3;
			padding: 3px 0px 2px 10px;
			position: relative;
			margin-#{$left}: 10px;

			&:after{
				content: '';
				height: 20px;
				width: 2px;
				background-color: $color-yellow;
				position: absolute;
				#{$left}: 0;
				top: 6px;
			}

			&.no-line{
				&:after{
					content: unset;
				}
			}

			@include respond-to('huge') {
				margin: 0;
				font-size: 15px;
			} //---huge

			&_grand{
				position: relative;
				&:before{
					content: '';
					position: absolute;
					top: 15px;
					#{$left}: 6px;
					border: 5px solid #fff;
					border-top-color: transparent;
					border-right-color: transparent;
					transform: rotate(45deg);
				}
				ul{
					display: none;
					padding: 0;
					list-style-type: none;
					position: absolute;
					box-shadow: 0 0 10px rgba(0,0,0,.25);
					top: 50%;
					#{$right}: calc(100% - 25px);
					z-index: 10;

					li{
						white-space: nowrap;
						filter: brightness(1.1) !important;

						&:hover{
							filter: brightness(1) !important;
						}
					} //li
				} //ul
				&:hover{
					ul{
						display: block;
					}
				} //HOVER
			} //_grand

			&_parent{
				position: relative;
				&>ul{
					position: absolute;
					display: none;
					#{$right}: 0;
					background-color: transparent;
					list-style-type: none;
					padding: 8px 0;
					li{
						background-color: $brand-color;
						padding: 6px 25px 6px 15px;
						position: relative;
						white-space: nowrap;
						min-height: 40px;
						filter: none;
						transition: .2s;

						&:after{
							content: '';
							width: 15px;
							height: 30%;
							position: absolute;
							#{$right}: 0px;
							top: 35%;
							border-radius: 50% 0 0 50%;
							background-color: #fff;
						} //after
						a{
							display: block;
							width: 100%;
							height: 100%;
						} //a
						&:hover{
							filter: brightness(.9);
							z-index: 10;
							a{

							} //a
						} //HOVER
					} //li
				} //ul
				&:hover{
					&>ul{
						display: block;
					} //ul
				} //HOVER
			} //_parent
		} //_item
		/*menu item*/
		&_soc {
			a{

							@include respond-to('smallest') {
								font-size: 8px;
								white-space: nowrap;
							}
			}
			position: relative;
			margin-left: 1px;
			display: inline;
			@include respond-to('large') {
				margin: 0;
			}
			@include respond-to('small') {
				margin: 0;
							float:left;
			}
			@include respond-to('extra-small') {


			}
			&:hover {
				i,
				img {
					transform: scale(1.3,1.3);
					@include respond-to('small') {
				transform: unset;
					}
					@include respond-to('extra-small') {
						transform: unset;

					}
				}
			}
			/*soc hover*/
			&_link {

				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}

			}

			&_wz {
		max-width:40px;
				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('extra-small') {
					margin: 0;
					padding: 0;
									max-width:20px;
				}
			}
			/*wz*/
			&_yt {
				color: $youtube;
		max-width:35px;
				&-bg {
					color: $color-white;
					font-size: 1.6rem;
				}
				/*yt bg*/
				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('extra-small') {
					margin: 0;
					padding: 0;
									max-width:20px;
				}
			}
			/*yt*/
			&_fb {
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background-color: $facebook;
				color: #fff;
				font-size: 16px;
				padding-top: 2px;
				display: inline-block;
				text-align: center;
				@include respond-to('large') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('small') {
					margin: 0;
					padding: 0;
				}
				@include respond-to('extra-small') {
					margin: 0;
					padding: 0;
									max-width:20px;
				}
			}
			/*fb*/
		}
		/*soc*/
		&_phone {
			&:hover &_icn {
				transform: rotate(220deg);
				color: $color-white;
				@include respond-to('smallest') {
									font-size: 8px;
								}
			}

			&_icn {
				padding: 0 30px;
				transform: rotate(260deg);
				font-size: 2rem;
				color: $brand-color;
				@include respond-to('large') {
					padding: 0;
				}
				@include respond-to('small') {
					font-size: 1.1rem;
					padding: 0;
				}

				&_num {
					color: $color-black;
					font-size: 18px;
						white-space: nowrap;
					@include respond-to('small') {
    			 	font-size: 11px;
					}
					@include respond-to('extra-small') {
					 	font-size: 10px;
					}

				}
				/*num*/
			}
			/*icn*/
		}
		/*phone*/
	}
	/*menu*/
	&__logo {
		transition: all 0.5s ease;
		opacity: 1;
		position: relative;
		float: $left;
		//top: 5px;
		//left: 33px;
		z-index: 10;
		transition: all 0.5s ease;

		@include respond-to('huge'){
			max-width: 20%;
		}

		&_link {
			display: inline-block;
			width: 100%;
			height: 100%;
			z-index: 100;

		}

		&:hover {
			transform: scale(1.1,1.1);
		}

		&_sm {
			opacity: 0;
			position: absolute;
			transition: all 0.5s ease;
			z-index: 10;
			display: inline-block;
			top: 0;
			float: $left;
			left: 50px;
			position: relative;

			display: none;

			&_link {
				display: inline-block;
				width: 100%;
				height: 100%;
				z-index: 222222222222228;
			}

			&:hover {
				transform: scale(1.1,1.1);
			}
		}
	}
}
/*main header*/
//Header for mobile devices
.header-mobile {
	position: fixed;
	display: none;
	height: 7rem;
	width: 100%;
	background: #fff;
	z-index: 5;
	@include respond-to('medium') {
		display: block;
		top: 0;
	}
	@include respond-to('small') {
		display: block;

	}
	@include respond-to('extra-small') {

	}
	@include respond-to('smallest') {
		display: block;
			height: 65px;
	}
	.hamburger-inner,
	.hamburger-inner::after,
	.hamburger-inner::before {
		background-color: $brand-color;
	}

	.header-mobile-logo {
		padding: 5px 15px;
		display: block;
		max-height: 100%;
		top: 0px;
		float: $left;

		@include respond-to('mobile-l'){
			max-width: 40%;
		}

		img {
			height: 65px;

			@include respond-to('mobile-l'){
				height: auto;
			}
		}
		//img
	}
	//header-mobile-logo

	.mobile-menu-btn {
		float: $right;
		top: 0;
		margin: 19px 12px;
		z-index: 100;

		&.is-active .hamburger-box {
 			transition: all 0.25s ease;
			z-index: 22222222;
			padding: 1px;
			border-radius: 60%;
			color:red;
			background: rgba(255, 255, 255, 1);


			@at-root .sb-slidebar {
				background-color: $brand-color;
				z-index: 2;


				nav {
					position: relative;
					z-index: 222222222222222222222222222222222222222222222222222222222;
					position: relative;
					top: 10vh;
					padding-right: 30px;
				}
			}
		}
	}

	.header-main{
		&__menu{
			&_phone{
				&_icn{

					font-size: 25px;
				}
			}
			&_soc{
				margin: 13px 0;
				/*display: block;
				float: none;*/
				display: inline-block;
				float: $right;
				max-width: 100%;
				a{
					margin-#{$left}: 20px;

					@include respond-to('mobile-l'){
						margin-#{$left}: 5px;
					}
				}
			} //_soc
		} //__menu
	} //header-main

	.header-mobile .header-main__menu_phone_icn{

	}
	.fa-phone {
		margin-top: 5px;
		    margin-right: 5px;
	}
	.fa-facebook{
		line-height: normal;
		top: 4px;
		left: -4px;
	    margin-right: 5px;
	}

	.header-main__menu_soc_fb{
		background-color: #fff;
		color: #405d9a;
		font-size: 25px;
	    margin-bottom: 17px;
	}

	.fa-stack-1x {
		line-height: normal;
	}
	.fa-youtube-play{
		font-size: 25px;
		    margin-right: 5px;
			top: 0px;
	}
	.header-main__menu_soc_wz{
		margin-top: 4px;
		max-width: 25px;
	}
	.header-main__menu_soc_link, .header-main__menu_soc_wz, .header-main__menu_soc_yt{
			margin-bottom: 11px;
    		margin-left: 6px;
	}

	.header-mobile .header-main__menu_soc a{
		margin-left: 3px;
	}
	.header-mobile .header-main__menu_soc{
		width: 100%;
	}


} //header-mobile
.header-top{
	border-bottom: 2px solid $color-green;
	width: calc(100% - 30px);
	margin: 0 auto;
	height: 100%;
	padding: 5px 0;
	pointer-events: all;
} //header-top
.language-menu{
	float: $left;
	margin: 50px 0 0 35px;

	@include respond-to('huge'){
		margin: 0;
	}
	li{
		display: inline-block;
		margin: 0 15px;
		a{
			color: #b5d8ff;
			font-size: 21px;
		} //a
	} //li
} //language-menu
.header-bottom{
	width: calc(100% - 30px);
	margin: 0 auto;
	pointer-events: all;
	&__menu{
		text-align: center;
		margin: 0;
		padding: 0;
		li{
			display: inline-block;
			margin-#{$left}: 40px;
			position: relative;
			top: 0;
			left: 0;
			transition: .2s;

			@include respond-to('huge'){
				margin-#{$left}: 15px;
			}

			&:hover{
				top: 2px;
				left: 3px;

				a{
					text-shadow: -2px -3px 2px rgba(0,0,0,1);
				}
			}

			&:last-of-type{
				margin-#{$left}: 0;
			}
			a{
				font-size: 25px;
				font-weight: 700;
				color: #ffdce3;
				text-shadow: 0 0 0 rgba(0,0,0,0);

				@include respond-to('huge'){
					font-size: 16px;
				}
			} //a
		} //li
	} //__menu
} //header-bottom
//header-mobile



@media screen and (min-width: 990px) {
	.waze{
		display: none;
	}
}

@media (max-width: 768px){
	.side-form__hidden .side-form__reveal-btn_text>span {
	    width: 50%;
	    margin-right: 38px;
	}
	.side-form__hidden .side-form__reveal-btn_text>img {
	   /* left: 61px;
	    top: -4px;*/
	}
	.side-form__reveal-btn{
		left:0;
	}
	.side-form__hidden .side-form__reveal-btn_text>img{
    	top: -4px !important;
		right: 0px;
	}

}
