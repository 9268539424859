.gallery-thumb{
  $p: &;

  overflow: hidden;
  position: relative;
  height: 200px;
  margin-bottom: 30px;
  box-shadow: none;
  transition: .3s;

  &__img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1.1);
    transition: .2s;
    min-width: 100%;
    min-height: 100%;
  } //__img

  &:hover{
    box-shadow: 0 0 13px rgba(0,0,0,.45);
    #{$p}__img{
      transform: translate(-50%,-50%) scale(1);
    }
  } //HOVER
} //gallery-thumb

.gallery-menu{
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  list-style-type: none;
  padding: 0;
  transition: .5s;

  @include respond-to('small'){
    overflow: hidden;
    max-height: 35px;
  }

  &__open{
    max-height: 600px;
  }


  &__item{
    border-bottom: 1px solid #e2e2e2;
    background-color: #fff;
    min-height: 50px;
    padding: 0;

    @include respond-to('small'){
      min-height: unset;
    }

    &:first-of-type{
      display: none;
      padding: 7px 15px;
      height: 35px;
      font-weight: 600;
      cursor: pointer;

      @include respond-to('small'){
        display: block;
      }
    }

    a{
      width: 100%;
      height: 100%;
      padding: 13px 10px;
      display: block;

      @include respond-to('small'){
        padding: 7px 7px;
      }
    }

    span{
      color: #777;
      font-size: 17px;
      line-height: 18px;
    } //span

    &:hover, &_selected{
      span{
        color: $color-turquise;
      }
    }
  } //__item
} //gallery-menu
